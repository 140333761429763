(function () {
  'use strict';

  class MessagesRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    messages() {
      return this.$resource(`${this.env.api}/messages`);
    }

    myMessages() {
      return this.$resource(`${this.env.api}/messages/my-messages`);
    }

    thread() {
      return this.$resource(`${this.env.api}/messages/:thread_id`, {thread_id: '@threadId'});
    }

    message() {
      return this.$resource(`${this.env.api}/messages/:message_id`, {message_id: '@messageId'}, {update: {method: 'PUT'}});
    }

  }

  /**
   * @ngdoc service
   * @name components.service:MessagesRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('MessagesRest', MessagesRest);
}());
